import Vue from 'vue'
import Rollbar from 'rollbar'

const createRollbarInstance = function (accessToken) {
  const config = {"captureUncaught":true,"captureUnhandledRejections":true,"enabled":true,"environment":"production","ignoredMessages":["Request failed with status code 401","Request failed with status code 400","Request failed with status code 404","Request failed with status code 422"],"payload":{"client":{"javascript":{"code_version":"7eba2f6f60cc0ae0268e82e0d416acafbaabfef9"}}}}
  config.accessToken = accessToken
  return new Rollbar(config)
}

const clientRollbar = createRollbarInstance('f48beb9307f54768a946ea1cc05fe7dd')

const noop = function () {}
const stubRollbar = Object.create(null)
Object.defineProperties(
  stubRollbar,
  Object.getOwnPropertyNames(Rollbar.prototype).reduce(function (acc, key) {
    acc[key] = { value: noop }
    return acc
  }, {}))

const RollbarAdapter = Object.create(null)
Object.defineProperty(RollbarAdapter, 'instance', {
  get() {
    if (process.client) {
      return clientRollbar
    }

    return stubRollbar
  },
})

export default function (ctx, inject) {
  // Inject Rollbar to the context as $rollbar
  ctx.$rollbar = RollbarAdapter.instance
  inject('rollbar', RollbarAdapter.instance)
  Vue.rollbar = RollbarAdapter.instance
}
